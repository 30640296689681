import {css} from 'lit';

/* shared styles for all views */
export const sharedStyles = css`
p {
    font-family: 'Roboto', sans-serif;
}

h1,
h2 {
    text-transform: uppercase;
}

nav {
    font-size: 14px;
}

a {
    color: var(--black);
    font-weight: 500;
}

a:visited {
    color: var(--black);
}

details {
    margin: 1em 0;
    user-select: none;
    border-top: 1px solid #e9e9e9;
    border-bottom: 1px solid #e9e9e9;
}

summary {
    display: flex;
    cursor: pointer;
    padding: 10px 0;
    text-transform: uppercase;
    font-weight: 700;
}

summary::-webkit-details-marker {
    display: none;
}

details > summary > .icon {
    transition: all 0.3s;
    margin: auto 10px auto auto;
}

details > summary > .icon > .minus {
    display: none;
}

details[open] > summary > .icon > .minus {
    display: block;
}

details[open] > summary > .icon > .plus {
    display: none;
}

button,
a.button {
    background: var(--blue);
    color: var(--white);
    border-radius: 32px;
    padding: 10px 15px;
    border: none;
    text-align: center;
    text-transform: uppercase;
    text-decoration: none;
    font-weight: bold;
    font-family: inherit;
    font-size: 15px;
    cursor: pointer;
    margin: 10px 5px;
    max-width: 50vw;
}

a.button {
    display: block;
}

button:hover,
a.button:hover {
    background-color: #0272A9;
}

button[disabled] {
    cursor: not-allowed;
    opacity: 25%;
}

button.link {
    background: none;
    color: var(--black);
    padding: 0px;
    text-transform: none;
    text-decoration: underline;
    font-weight: normal;
}

label {
    font-family: 'Roboto', sans-serif;
    display: flex;
    flex-direction: column;
    text-align: left;
    margin: 10px 0;
}

label.checkbox {
    display: block;
}

input,
select,
textarea {
    font-family: inherit;
    border: 1px solid #ccc;
    border-radius: 3px;
    background: var(--white);
    padding: 10px;
}

input::placeholder,
select::placeholder,
textarea::placeholder {
    color: #ccc;
}

input:focus,
select:focus,
textarea:focus {
    outline: none;
    border-color: var(--black);
    -webkit-transition: border-color ease 300ms;
    transition: border-color ease 300ms;
}

input:disabled
select:disabled,
textarea:disabled {
    opacity: 0.7;
}

input:out-of-range {
    border-color: red;
}

select[name="recurrence_pattern_interval"] {
    text-transform: capitalize;
}

select[name="recurrence_pattern_interval"]>option {
    text-transform: capitalize;
}

input[type="checkbox"] {
    margin: auto 10px;
}
`;

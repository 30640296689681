import {LitElement, html, css} from 'lit';
import {sharedStyles} from '../shared-styles';
import '../elements/run-user-manager';
import '../elements/channel-user';
import '../elements/channel-name';
/**
 * `run-page`
 *  Allow the creation of a run for a break
 */
export class RunPage extends LitElement {
  /**
   * Defines the elements styles
   *
   * @return {CSSResult} the resulting styles
   */
  static get styles() {
    const style = css`
      button {
        margin: 10px;
      }

      channel-user {
        margin: 5px;
      }

      details.groups>div.groups {
        display: flex;
        flex-wrap: wrap;
      }

      details.groups>div.groups>div.group {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        padding: 0 35px;
      }

      details.groups>div.groups>div.group>div.users {
        display: flex;
        flex-wrap: wrap;
      }

      details>button {
        width: 100%;
      }
    `;

    return [sharedStyles, style];
  }

  /**
   * Defined the elements content
   *
   * @return {TemplateResult} the resulting html template
   */
  render() {
    if (this.isError) {
      return html`<p class="error">Oops there has been an error. <a href="/">Go home</a></p>`;
    }

    if (this.isLoading) {
      return html`<p class="loading">Loading your channel...</p>`;
    }

    const nav = html`
      <nav>
        <a href="/channels">Channels</a>
        /
        <a href="/channels/${this.routeData?.params?.channelID}"><channel-name .channel=${this.channel}></channel-name></a>
        /
        <a href="/channels/${this.routeData?.params?.channelID}/breaks">Break</a>
        /
        <a href="/channels/${this.routeData?.params?.channelID}/breaks/${this.routeData?.params?.breakID}">${this.break.name}</a>
        /
        <a href="/channels/${this.routeData?.params?.channelID}/breaks/${this.routeData?.params?.breakID}/runs">Create Run</a>
        /
      </nav>`;

    if (this.isComplete) {
      const completed = html`<p class="complete">Sending out your teabreak messages now!</p>`;
      return [nav, completed];
    }

    if (!this.channel?.users?.length) {
      const empty = html`<p class="empty">There aren't any users in this channel</p>`;
      return [nav, empty];
    }

    const creation = html`
    <details class="breaks" open>
        <summary>Choose users to include in tea break run<span class="icon"><span class="plus">+</span><span class="minus">-</span></span></summary>
        <run-user-manager .channel=${this.channel}></run-user-manager>
        <button @click="${this.createRun}">Group these included users</button>
      </details>`;

    if (!this.run.groups) {
      return [nav, creation];
    }

    const run = html`
    <details class="groups" open>
        <summary>
          <p>Here are your groups, not happy with these groups?</p>
          <button @click="${this.createRun}">Rematch</button>
          <span class="icon"><span class="plus">+</span><span class="minus">-</span></span>
        </summary>

        <div class="groups">
        ${this.run?.groups?.map((group, i) => {
    const users = group.map((member) => this.channel.users.find((user) => user.id === member));
    return html`
            <div class="group">
              <p>Group #${i + 1}</p>
              <div class="users">
                ${users.map((user) => html`<channel-user .user=${user}></channel-user>`)}
              </div>
            </div>
          `;
  })}
        </div>

        <button @click="${this.startRun}" ?disabled=${this.isStarting}>Ready to go?</button>
        <p class="starting" ?hidden=${!this.isStarting}>Starting the teabreak...</p>
      </details>`;

    return [nav, creation, run];
  }

  /**
   * Defines the elements properties
   *
   * @return {object} the props
   */
  static get properties() {
    return {
      /** The data parsed from the route url */
      routeData: {type: Object},
      /** The service for getting data */
      service: {type: Object},
      /** The channel info */
      channel: {type: Object},
      /** The break info */
      break: {type: Object},
      /** If we're loading the channels */
      isLoading: {type: Boolean},
      /** If there was an error loading channels */
      isError: {type: Boolean},
      /** If the teabreak run is starting */
      isStarting: {type: Boolean},
      /** If the teabreak run is completed */
      isComplete: {type: Boolean},
      /** The run info */
      run: {type: Object},
    };
  }

  /** Initialises values of properties */
  constructor() {
    super();
    this.service = {};
    this.channel = {};
    this.break = {};
    this.run = {};
    this.isLoading = true;
    this.isError = false;
    this.isComplete = false;
    this.isStarting = false;
  }

  /** Add any event listeners */
  async connectedCallback() {
    if (super.connectedCallback) {
      super.connectedCallback();
    }
    this.addEventListener('user-excluded-change', this.handleUserExcludedChange);
  }

  /** Remove any event listeners */
  disconnectedCallback() {
    if (super.disconnectedCallback) {
      super.disconnectedCallback();
    }
    this.removeEventListener('user-excluded-change', this.handleUserExcludedChange);
  }

  /**
   * Fired whenever any property changes and the template updates
   *
   * @param {PropertyValues} changedProperties map of changed properties
   */
  updated(changedProperties) {
    if (super.updated) {
      super.updated();
    }

    if ((changedProperties.has('routeData') || changedProperties.has('service')) &&
      this.routeData?.params?.page === 'run' &&
      this.service?.getChannel) {
      const channelID = this.routeData?.params?.channelID;
      const breakID = this.routeData?.params?.breakID;
      this.loadChannelBreakData(channelID, breakID);
    }
  }

  /**
   * Call to load the list of channels
   *
   * @param {string} channelID the channel id
   * @param {string} breakID the break id
   */
  async loadChannelBreakData(channelID, breakID) {
    this.isComplete = false;
    this.isError = false;
    this.isLoading = true;
    try {
      this.channel = await this.service.getChannel(channelID);
      this.break = await this.service.getBreak(channelID, breakID);
    } catch (e) {
      this.isError = true;
    } finally {
      this.isLoading = false;
    }
  }

  /**
   * Handle changing the users exclusion status,
   * and setting it on the channel so we don't have to do a dirty check
   * @param {string} userID the user id
   * @param {boolean} isExcluded the excluded bool
   */
  changeUserExcludedStatus(userID, isExcluded) {
    const user = this.channel.users.find((user) => user.id === userID);
    if (!user) {
      return;
    }

    user.is_excluded = isExcluded;
    this.channel = {
      ...this.channel,
    };
  }

  /**
   * Receive user-excluded-change events and set the users exclusion status appropriately
   * @param {CustomEvent} event the user-excluded-change event
   */
  handleUserExcludedChange(event) {
    this.changeUserExcludedStatus(event.detail.id, event.detail.isExcluded);
  }

  /**
   * Helper to get the details section
   * @return {HTMLElement} the details section
   */
  get breaksDisplay() {
    return this.shadowRoot.querySelector('details.breaks');
  }

  /**
   * Handle creating a run, grab the list of included users
   * Created a break run with isStarted false so we don't send the slack messages
   * until the user clicks ready to go
   */
  async createRun() {
    try {
      const includedUsers = this.channel.users.filter((user) => !user.is_excluded).map((user) => user.id);
      const channelID = this.routeData?.params?.channelID;
      const breakID = this.routeData?.params?.breakID;
      this.run = await this.service.createBreakRun(channelID, breakID, includedUsers, false);
      this.breaksDisplay.open = false;
    } catch (e) {
      this.isError = true;
    }
  }

  /**
   * Trigger starting the tea break run, by updating isStarted to be true
   */
  async startRun() {
    try {
      this.isStarting = true;
      const channelID = this.routeData?.params?.channelID;
      const breakID = this.routeData?.params?.breakID;
      await this.service.updateBreakRun(channelID, breakID, this.run.id, true);
      this.isComplete = true;
    } catch (e) {
      this.isError = true;
    } finally {
      this.isStarting = false;
    }
  }
}
window.customElements.define('run-page', RunPage);

import {LitElement, html} from 'lit';
import {ifDefined} from 'lit/directives/if-defined.js';
import {getFormJSON} from './forms';

/**
 * `break-form`
 *  Handle displaying a form to create/update a break
 */
export class BreakForm extends LitElement {
  /**
     * Render template without shadow DOM.
     * @return {any} the render root
     */
  createRenderRoot() {
    return this;
  }

  /**
     * Wrapper to render the basic cron editor
     *
     * @return {TemplateResult} the resulting html template
     */
  renderBasicCronEditor() {
    return html`
    <label>How often do you want this break to reoccur?
      <select
        name="recurrence_pattern_interval"
        required
        @input=${this.recurrenceIntervalChange}>
        <option value="">--Please choose an option--</option>
        ${this.intervals.map((interval) => {
    const selected = this?.parsedBreak?.recurrence_pattern_interval === interval;
    return html`<option value="${interval}" ?selected=${selected}>${interval}</option>`;
  })}
      </select>
    </label>
    <label>At what time do you want the teabreak to happen?
      <input
        type="time"
        placeholder="HH:mm"
        pattern="[0-9]{2}:[0-9]{2}"
        name="recurrence_pattern_time"
        required
        .value="${ifDefined(this?.parsedBreak?.recurrence_pattern_time)}"
      />
    </label>
    ${this?.parsedBreak?.recurrence_pattern_interval === 'weekly' ?
      html`
      <label>What day of the week would you like the teabreak to occur?
      <select
          class="weekly"
          name="recurrence_pattern_day"
          required>
          <option value="">--Please choose an option--</option>
          ${this.weekDays.map((weekDay) => {
        const selected = this?.parsedBreak?.recurrence_pattern_day === weekDay.value;
        return html`<option value="${weekDay.value}" ?selected=${selected}>${weekDay.display}</option>`;
      })}
        </select>
      </label>` :
      html`
      <label>What day of the month would you like the teabreak to occur?
      <input
          class="days"
          name="recurrence_pattern_day"
          type="number"
          min="1"
          max="31"
          required
          .value="${ifDefined(this?.parsedBreak?.recurrence_pattern_day)}">
      </label>`}
    </label>`;
  }

  /**
     * Wrapper to render the advanced cron editor
     *
     * @return {TemplateResult} the resulting html template
     */
  renderAdvancedCronEditor() {
    return html`
    <label>What cron schedule would you like your teabreak to run on?
    <br>We don't support a daily or more frequent interval.
    <br>Times are in UTC. <a href="https://docs.aws.amazon.com/eventbridge/latest/userguide/eb-cron-expressions.html">Supported formats</a>.
      <input
        type="text"
        placeholder="5 4 * * * *"
        name="recurrence_pattern"
        required
        .value="${ifDefined(this?.parsedBreak?.recurrence_pattern)}"
      />
    </label>
    `;
  }

  /**
     * Defined the elements content
     *
     * @return {TemplateResult} the resulting html template
     */
  render() {
    return html`
    <form @submit=${this.handleBreakSubmission}>
      <label>Break Name:
        <input
          type="text"
          name="name"
          required
          .value="${ifDefined(this?.parsedBreak?.name)}"
        />
      </label>
      <label>Group Size:
        <input
          type="number"
          name="group_size"
          min="2"
          required
          .value=${ifDefined(this?.parsedBreak?.group_size)}
        />
      </label>
      <label>Welcome Message:
        <textarea
          name="welcome_message"
          rows=3
          required
          .value="${ifDefined(this?.parsedBreak?.welcome_message)}"
        ></textarea>
      </label>
      <label class="checkbox">Do you want this teabreak to automatically run?
          <input
            type="checkbox"
            name="is_auto_recurring"
            ?checked=${this?.parsedBreak?.is_auto_recurring}
            @input=${this.autorecurrenceChange}
          />
      </label>
      <fieldset
        id="autoRecurring"
        ?disabled=${!this?.parsedBreak?.is_auto_recurring}
        ?hidden=${!this?.parsedBreak?.is_auto_recurring}>

        <legend>Automatically Run, use
        <button class="link" type="button" @click=${this.switchEditor}>${this.isAdvancedScheduler ? 'basic' : 'advanced'} editor.</button>
        </legend>

        ${this.isAdvancedScheduler ? this.renderAdvancedCronEditor() : this.renderBasicCronEditor()}
      </fieldset>
      ${this.parsedBreak?.name ?
        html`<button type="submit">Update Break</button>` :
        html`
      <button type="submit">Create Break</button>
      <button type="reset">Cancel</button>`
}
      </form>
    `;
  }

  /**
     * Defines the elements properties
     *
     * @return {object} the props
     */
  static get properties() {
    return {
      /** The existing break info */
      break: {type: Object},
      /** The existing break info, parsed to match the form for e.g. recurrence information */
      parsedBreak: {type: Object},
      /** If we should show the advanced scheduling editor */
      isAdvancedScheduler: {type: Boolean},
    };
  }

  /** Initializes values of properties */
  constructor() {
    super();
    this.isAdvancedScheduler = false;
    this.intervals = ['weekly', 'fortnightly', 'monthly', 'quarterly'];
    this.weekDays = [
      {
        display: 'Monday',
        value: '2',
      },
      {
        display: 'Tuesday',
        value: '3',
      },
      {
        display: 'Wednesday',
        value: '4',
      },
      {
        display: 'Thursday',
        value: '5',
      },
      {
        display: 'Friday',
        value: '6',
      },
      {
        display: 'Saturday',
        value: '7',
      },
      {
        display: 'Sunday',
        value: '1',
      },
    ];
  }

  /**
   * Fired whenever any property changes and the template updates
   *
   * @param {PropertyValues} changedProperties map of changed properties
   */
  updated(changedProperties) {
    if (super.updated) {
      super.updated();
    }

    if (changedProperties.has('break')) {
      this.parsedBreak = this.mapBreakToForm(this.break);
    }
  }

  /**
     * Handles the submission of the break form, getting the data from the form and fire an event
     *
     * @param {Event} event the submission event
     */
  async handleBreakSubmission(event) {
    event.preventDefault();
    const form = this.querySelector('form');
    const valid = form.reportValidity();
    if (valid) {
      const {
        recurrence_pattern_interval: interval,
        recurrence_pattern_time: time,
        recurrence_pattern_day: day,
        ...data
      } = getFormJSON(form);

      const pattern = data?.recurrence_pattern || BreakForm.buildCron(interval, time, day);

      this.dispatchEvent(new CustomEvent('break-form-submission', {
        detail: {
          ...data,
          is_auto_recurring: data?.is_auto_recurring === 'on',
          recurrence_pattern: pattern,
        },
        bubbles: true,
        composed: true,
      }));
    }
  }

  /**
   * Uses the basic input fields to construct a cron we can run on
   *
   * @param {String} interval the recurrence interval
   * @param {String} time  the recurrence time
   * @param {String} day the recurrence day
   * @return {String} the constructed cron
   */
  static buildCron(interval, time, day) {
    if (!interval || !time || !day) {
      return '';
    }

    const [localHours, localMinutes] = time.split(':');
    const now = new Date();
    now.setHours(localHours, localMinutes);
    const hours = now.getUTCHours();
    const minutes = now.getUTCMinutes();

    switch (interval) {
      case 'weekly': {
        return `${minutes} ${hours} ? * ${day} *`;
      }
      case 'fortnightly': {
        const range = BreakForm.buildCronFortnightlyRange(Number.parseFloat(day));
        return `${minutes} ${hours} ${range} * ? *`;
      }
      case 'monthly': {
        return `${minutes} ${hours} ${day} * ? *`;
      }
      case 'quarterly': {
        return `${minutes} ${hours} ${day} */3 ? *`;
      }
      default: {
        return '';
      }
    }
  }

  /**
   * Build up the days of the month a fortnightly run should run on
   * Fortnightly runs happen every 14 days so calculate how many we can
   * do in a month
   * Keep the original day first to allow us to parse it back out for updating
   * @param {Number} day the start day of recurrence
   * @return {String} the days to run on
   */
  static buildCronFortnightlyRange(day) {
    if (day + 28 < 31) {
      return `${day},${day + 14},${day + 28}`;
    }
    if (day - 28 > 0) {
      return `${day},${day - 14},${day - 28}`;
    }
    if (day > 15) {
      return `${day},${day - 14}`;
    }
    return `${day},${day + 14}`;
  }

  /**
   * Switch between the advanced and basic editor modes
   */
  switchEditor() {
    this.isAdvancedScheduler = !this.isAdvancedScheduler;
  }

  /**
   * Switch between auto recurring and not
   * Enabling the correct form fields to be shown or hidden
   */
  autorecurrenceChange() {
    const value = this.querySelector('input[name="is_auto_recurring"]').checked;
    this.break = {
      ...this.break,
      is_auto_recurring: value,
    };
  }

  /**
   * Switch between recurrence intervals
   * Setting the max date for the recurrence start
   */
  recurrenceIntervalChange() {
    const value = this.querySelector('select[name="recurrence_pattern_interval"]').value;
    this.break = {
      ...this.break,
      recurrence_pattern_interval: value,
    };
  }

  /**
   * Convert the saved teabreak to include the basic cron editor inputs
   * of interval, time, day
   * @param {Object} teabreak the existing teabreak
   * @return {Object} the teabreak mapped to form fields
   */
  mapBreakToForm(teabreak) {
    if (!teabreak?.recurrence_pattern) {
      return teabreak;
    }
    const {interval, time, day} = this.parseCron(teabreak?.recurrence_pattern);
    return {
      ...teabreak,
      recurrence_pattern_interval: interval,
      recurrence_pattern_time: time,
      recurrence_pattern_day: day,
    };
  }

  /**
   * Parse any existing cron into values for our basic cron editor inputs
   * The users chosen day will always be the first value in the list of days
   * Possible cron formats:
   * Weekly: 12 10 ? * 4 *
   * Fortnightly: 12 10 2,16,30 * ? *
   * Monthly: 12 10 02 * ? *
   * Quarterly: 12 10 02 *\/3 ? *
   * @param {Object} cron the existing cron pattern
   * @return {Object} the parsed fields for the basic cron editor: interval, time, start
   */
  parseCron(cron) {
    const [minutes, hours, days, monthly, dayOfWeek] = cron.split(' ');

    const now = new Date();
    now.setUTCHours(hours, minutes);
    const displayHours = `${now.getHours()}`.length === 1 ? `0${now.getHours()}` : now.getHours();
    const displayMinutes = `${now.getMinutes()}`.length === 1 ? `0${now.getMinutes()}` : now.getMinutes();
    const time = `${displayHours}:${displayMinutes}`;

    if (this.weekDays.map((weekDay) => weekDay.value).includes(dayOfWeek)) {
      return {
        interval: 'weekly',
        time,
        day: dayOfWeek,
      };
    }

    const [day] = days.split(',');
    if (days.indexOf(',') > -1) {
      return {
        interval: 'fortnightly',
        time,
        day,
      };
    }
    if (monthly.indexOf('/3') > -1) {
      return {
        interval: 'quarterly',
        time,
        day,
      };
    }
    const dayOfMonth = Number.parseFloat(day);
    if (dayOfMonth >= 1 && dayOfMonth <= 31) {
      return {
        interval: 'monthly',
        time,
        day,
      };
    }

    this.isAdvancedScheduler = true;
    return {
      interval: '',
      time: '',
      day: '',
    };
  }
}
window.customElements.define('break-form', BreakForm);

import {LitElement, html, css} from 'lit';
import {sharedStyles} from '../shared-styles';
import '../elements/channel-name';
/**
 * `channels-page`
 *  Display the list of channels for a user
 */
export class ChannelsPage extends LitElement {
  /**
   * Defines the elements styles
   *
   * @return {CSSResult} the resulting styles
   */
  static get styles() {
    const style = css`
      div.channels {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
      }

      div.channels > a.channel {
        padding: 10px 5px;
      }
    `;

    return [sharedStyles, style];
  }
  /**
   * Defined the elements content
   *
   * @return {TemplateResult} the resulting html template
   */
  render() {
    if (this.isError) {
      return html`<p class="error">Oops there has been an error. <a href="/">Go home</a></p>`;
    }

    if (this.isLoading) {
      return html`<p class="loading">Loading your channels...</p>`;
    }

    const nav = html`
    <nav>
      <a href="/channels">Channels</a>
      /
    </nav>`;

    if (!this.channels.length) {
      const empty = html`<p class="empty">You do not have any channels you can create a tea break in</p>`;
      return [nav, empty];
    }

    const channels = this.channels.map((channel) => html`
      <a class="channel" href="/channels/${channel.id}">
        <channel-name .channel=${channel}></channel-name>
      </a>`);
    const content = html`
      <p>Please find the slack channels you can create a teabreak in, listed below:</p>
      <div class="channels">
        ${channels}
      </div>
    `;
    return [nav, content];
  }

  /**
   * Defines the elements properties
   *
   * @return {object} the props
   */
  static get properties() {
    return {
      /** The service for getting data */
      service: {type: Object},
      /** The list of channels */
      channels: {type: Array},
      /** If we're loading the channels */
      isLoading: {type: Boolean},
      /** If there was an error loading channels */
      isError: {type: Boolean},
    };
  }

  /** Initialises values of properties */
  constructor() {
    super();
    this.service = {};
    this.channels = [];
    this.isLoading = true;
    this.isError = false;
  }

  /**
   * Fired whenever any property changes and the template updates
   *
   * @param {PropertyValues} changedProperties map of changed properties
   */
  updated(changedProperties) {
    if (super.updated) {
      super.updated();
    }

    if (changedProperties.has('service') && this.service?.getChannels) {
      this.loadChannels();
    }
  }

  /**
   * Call to load the list of channels
   */
  async loadChannels() {
    this.isError = false;
    this.isLoading = true;
    try {
      const response = await this.service.getChannels();
      if (response) {
        this.channels = response;
      }
    } catch (e) {
      this.isError = true;
    } finally {
      this.isLoading = false;
    }
  }
}
window.customElements.define('channels-page', ChannelsPage);

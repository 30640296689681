import {LitElement, html, css} from 'lit';
import {repeat} from 'lit/directives/repeat.js';
import {sharedStyles} from '../shared-styles';
import './run-user';
/**
 * `run-user-manager`
 *  Handle displaying a list of users to include/exclude
 */
export class RunUserManager extends LitElement {
  /**
     * Defines the elements styles
     *
     * @return {CSSResult} the resulting styles
     */
  static get styles() {
    const style = css`
      :host {
        display: flex;
      }

      div.included,
      div.excluded {
        display: flex;
        flex-direction: column;
        margin: 0 auto;
      }

      div.users {
        height: 100%;
        min-width: 40vw;
        max-width: 48vw;
        display: flex;
        flex-wrap: wrap;
        border: 1px solid var(--black);
      }

      run-user {
        margin: 5px;
      }
    `;

    return [sharedStyles, style];
  }

  /**
     * Defined the elements content
     *
     * @return {TemplateResult} the resulting html template
     */
  render() {
    const includedUsers = this.channel.users.filter((user) => !user.is_excluded);
    const excludedUsers = this.channel.users.filter((user) => user.is_excluded);
    return html`
        <div class="included">
            <p>These ${includedUsers.length} user(s) are included:</p>
            <div class="users" @dragover=${RunUserManager.handleDragOver} @drop=${this.handleIncludedDrop}>
              ${repeat(includedUsers, (user) => user.id, (user) => {
    return html`<run-user draggable="true" .user=${user} @dragstart=${RunUserManager.handleDragUser}></run-user>`;
  })}
            </div>
        </div>
        <div class="excluded">
            <p>These ${excludedUsers.length} user(s) are excluded:</p>
            <div class="users" @dragover=${RunUserManager.handleDragOver} @drop=${this.handleExcludedDrop}>
              ${repeat(excludedUsers, (user) => user.id, (user) => {
    return html`<run-user draggable="true" .user=${user} @dragstart=${RunUserManager.handleDragUser}></run-user>`;
  })}
            </div>
        </div>
    `;
  }

  /**
     * Defines the elements properties
     *
     * @return {object} the props
     */
  static get properties() {
    return {
      /** The channel info */
      channel: {type: Object},
    };
  }

  /** Initializes values of properties */
  constructor() {
    super();
    this.channel = {};
  }

  /**
    * Fire an event when the user is excluded or not changes from dragging
    * @param {string} userID the user id
    * @param {boolean} isExcluded the excluded bool
    */
  changeUserExclusion(userID, isExcluded) {
    this.dispatchEvent(new CustomEvent('user-excluded-change', {
      detail: {
        id: userID,
        isExcluded,
      },
      bubbles: true,
      composed: true,
    }));
  }


  /**
     * Set the data to transfer when dragging a user
     * @param {Event} event the drag event
     */
  static handleDragUser(event) {
    event.dataTransfer.effectAllowed = 'move';
    event.dataTransfer.setData('userID', event.target.user.id);
  }

  /**
     * Prevent default drag over properties as we handle it ourselves
     * @param {Event} event the dragover event
     */
  static handleDragOver(event) {
    event.preventDefault();
    event.dataTransfer.dropEffect = 'move';
  }

  /**
     * Handle dropping the user into the included zone
     * @param {Event} event the drop event
     */
  handleIncludedDrop(event) {
    event.preventDefault();
    const userID = event.dataTransfer.getData('userID');
    this.changeUserExclusion(userID, false);
  }

  /**
     * Handle dropping the user into the excluded zone
     * @param {Event} event the drop event
     */
  handleExcludedDrop(event) {
    event.preventDefault();
    const userID = event.dataTransfer.getData('userID');
    this.changeUserExclusion(userID, true);
  }
}
window.customElements.define('run-user-manager', RunUserManager);

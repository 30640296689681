import {LitElement, html, css} from 'lit';
import {sharedStyles} from '../shared-styles';
import './channel-user';
/**
 * `run-user`
 *  Handle displaying a user
 */
export class RunUser extends LitElement {
  /**
   * Defines the elements styles
   *
   * @return {CSSResult} the resulting styles
   */
  static get styles() {
    const style = css`
      :host {
        display: block;
        max-width: 100px;
      }

      channel-user {
        pointer-events: none;
      }

      label {
        flex-direction: row;
        font-size: 0.8em;
      }
    `;

    return [sharedStyles, style];
  }

  /**
   * Defined the elements content
   *
   * @return {TemplateResult} the resulting html template
   */
  render() {
    return html`
        <channel-user .user=${this.user}></channel-user>
        <label>Is User Excluded?
            <input type="checkbox" ?checked=${this.user.is_excluded} @input=${this.handleExclusionChange} />
        </label>
    `;
  }

  /**
   * Defines the elements properties
   *
   * @return {object} the props
   */
  static get properties() {
    return {
      /** The user info */
      user: {type: Object},
    };
  }

  /** Initialises values of properties */
  constructor() {
    super();
    this.user = {};
  }

  /** Handle when the user is excluded or not */
  handleExclusionChange() {
    const input = this.shadowRoot.querySelector('input');
    this.dispatchEvent(new CustomEvent('user-excluded-change', {
      detail: {
        id: this.user.id,
        isExcluded: input.checked,
      },
      bubbles: true,
      composed: true,
    }));
  }
}
window.customElements.define('run-user', RunUser);

import {LitElement, html, css} from 'lit';
import router from 'page/page.mjs';
import '@github/time-elements';
import {sharedStyles} from '../shared-styles';
import '../elements/channel-user';
import '../elements/channel-name';
import '../elements/break-form';
/**
 * `break-page`
 *  Display the information about a specific break
 */
export class BreakPage extends LitElement {
  /**
   * Defines the elements styles
   *
   * @return {CSSResult} the resulting styles
   */
  static get styles() {
    const style = css`
      a.run {
        display: block;
        margin: 15px 10px;
      }

      details.runs>div>div.run {
        padding: 10px;
        border-bottom: 1px solid #e9e9e9;
      }
    `;

    return [sharedStyles, style];
  }

  /**
   * Defined the elements content
   *
   * @return {TemplateResult} the resulting html template
   */
  render() {
    if (this.isError) {
      return html`<p class="error">Oops there has been an error. <a href="/">Go home</a></p>`;
    }

    if (this.isLoading) {
      return html`<p class="loading">Loading your break...</p>`;
    }

    const header = html`
      <nav>
        <a href="/channels">Channels</a>
        /
        <a href="/channels/${this.routeData?.params?.channelID}"><channel-name .channel=${this.channel}></channel-name></a>
        /
        <a href="/channels/${this.routeData?.params?.channelID}/breaks">Break</a>
        /
        <a href="/channels/${this.routeData?.params?.channelID}/breaks/${this.routeData?.params?.id}">${this.break.name}</a>
        /
      </nav>
      <break-form .break=${this.break}></break-form>
    `;

    const create = html`
    <a
      class="run button"
      href="/channels/${this.routeData?.params?.channelID}/breaks/${this.routeData?.params?.id}/runs">
      Create Run
    </a>`;

    if (this.runs?.length) {
      const previousRuns = this.runs.map((run) => html`
      <div class="run">
        <h4>Run from <relative-time datetime=${run.created_date}></relative-time></h4>
        <p>This run had ${run?.groups?.length} group(s) involved.</p>
      </div>
    `);
      const runs = html`
      <p class="runs">There are ${this.runs.length} previous runs(s) of this teabreak.</p>
      <details class="runs">
        <summary>Previous Runs<span class="icon"><span class="plus">+</span><span class="minus">-</span></span></summary>
        <div>${previousRuns}</div>
      </details>`;
      return [header, runs, create];
    }
    return [header, create];
  }

  /**
   * Defines the elements properties
   *
   * @return {object} the props
   */
  static get properties() {
    return {
      /** The data parsed from the route url */
      routeData: {type: Object},
      /** The service for getting data */
      service: {type: Object},
      /** The break info */
      break: {type: Object},
      /** The channel info */
      channel: {type: Object},
      /** If we're loading the channels */
      isLoading: {type: Boolean},
      /** If there was an error loading channels */
      isError: {type: Boolean},
      /** The previous historic runs */
      runs: {type: Array},
    };
  }

  /** Initialises values of properties */
  constructor() {
    super();
    this.service = {};
    this.break = {};
    this.channel = {};
    this.runs = [];
    this.isLoading = true;
    this.isError = false;
  }

  /** Add any event listeners */
  async connectedCallback() {
    if (super.connectedCallback) {
      super.connectedCallback();
    }
    this.addEventListener('break-form-submission', this.handleBreakUpdate);
  }

  /** Remove any event listeners */
  disconnectedCallback() {
    if (super.disconnectedCallback) {
      super.disconnectedCallback();
    }
    this.removeEventListener('break-form-submission', this.handleBreakUpdate);
  }

  /**
   * Fired whenever any property changes and the template updates
   *
   * @param {PropertyValues} changedProperties map of changed properties
   */
  updated(changedProperties) {
    if (super.updated) {
      super.updated();
    }

    if ((changedProperties.has('routeData') || changedProperties.has('service')) &&
      this.routeData?.params?.page === 'break' &&
      this.service?.getChannel) {
      const channelID = this.routeData?.params?.channelID;
      const breakID = this.routeData?.params?.id;
      this.loadBreak(channelID, breakID);
    }
  }

  /**
   * Call to load the list of channels
   *
   * @param {string} channelID the channel id
   * @param {string} breakID the break id
   */
  async loadBreak(channelID, breakID) {
    this.isError = false;
    this.isLoading = true;
    try {
      this.channel = await this.service.getChannel(channelID);
      this.break = await this.service.getBreak(channelID, breakID);
      this.runs = await this.service.getBreakRuns(channelID, breakID);
    } catch (e) {
      this.isError = true;
    }
    this.isLoading = false;
  }

  /**
   * Handles the update of the break, from the break-form event
   *
   * @param {CustomEvent} event break-form-submission event
   */
  async handleBreakUpdate(event) {
    try {
      const channelID = this.routeData?.params?.channelID;
      const breakID = this.routeData?.params?.id;
      await this.service.updateBreak(breakID, channelID, event.detail);
      router.show(`/channels/${channelID}`);
    } catch (e) {
      this.isError = true;
    }
  }
}
window.customElements.define('break-page', BreakPage);

import {LitElement, html, css} from 'lit';
import {sharedStyles} from '../shared-styles';
import '../elements/channel-user';
import '../elements/channel-name';
/**
 * `channel-page`
 *  Display the information about a specific channel
 */
export class ChannelPage extends LitElement {
  /**
   * Defines the elements styles
   *
   * @return {CSSResult} the resulting styles
   */
  static get styles() {
    const style = css`
      details>div {
        display: flex;
        flex-wrap: wrap;
      }

      channel-user {
        margin: 15px;
      }

      details.breaks>div {
        flex-direction: column;
        margin: 15px 0;
      }
    `;

    return [sharedStyles, style];
  }

  /**
   * Defined the elements content
   *
   * @return {TemplateResult} the resulting html template
   */
  render() {
    if (this.isError) {
      return html`<p class="error">Oops there has been an error. <a href="/">Go home</a></p>`;
    }

    if (this.isLoading) {
      return html`<p class="loading">Loading your channel...</p>`;
    }

    const nav = html`
    <nav>
      <a href="/channels">Channels</a>
      /
      <a href="/channels/${this.routeData?.params?.id}"><channel-name .channel=${this.channel}></channel-name></a>
      /
    </nav>`;

    if (!this.channel?.users?.length) {
      const empty = html`<p class="empty">There aren't any users in this channel</p>`;
      return [nav, empty];
    }

    const header = html`
    <h3 class="name"><channel-name .channel=${this.channel}></channel-name></h3>
      <p class="users">There are ${this.channel.users.length} user(s) in this channel.</p>
      <details class="users">
        <summary>Users<span class="icon"><span class="plus">+</span><span class="minus">-</span></span></summary>
        <div>
        ${this.channel.users.map((user) => html`<channel-user .user=${user}></channel-user>`)}
        </div>
      </details>
    `;

    const create = html`<a class="button" href="/channels/${this.routeData?.params?.id}/breaks">Create New Tea Break For Channel</a>`;

    if (this.teabreaks?.length) {
      const breaks = html`
      <p class="breaks">There are ${this.teabreaks.length} teabreaks(s) in this channel.</p>
      <details class="breaks">
        <summary>TeaBreaks<span class="icon"><span class="plus">+</span><span class="minus">-</span></span></summary>
        <div>
        ${this.teabreaks.map((teabreak) => {
    return html`<a class="break" href="/channels/${this.routeData?.params?.id}/breaks/${teabreak.id}">${teabreak.name}</a>`;
  })}
        </div>
      </details>`;
      return [nav, header, breaks, create];
    }
    return [nav, header, create];
  }

  /**
   * Defines the elements properties
   *
   * @return {object} the props
   */
  static get properties() {
    return {
      /** The data parsed from the route url */
      routeData: {type: Object},
      /** The service for getting data */
      service: {type: Object},
      /** The channel info */
      channel: {type: Object},
      /** The list of teabreaks for this channel */
      teabreaks: {type: Array},
      /** If we're loading the channels */
      isLoading: {type: Boolean},
      /** If there was an error loading channels */
      isError: {type: Boolean},
    };
  }

  /** Initialises values of properties */
  constructor() {
    super();
    this.service = {};
    this.channel = {};
    this.teabreaks = [];
    this.isLoading = true;
    this.isError = false;
  }

  /**
   * Fired whenever any property changes and the template updates
   *
   * @param {PropertyValues} changedProperties map of changed properties
   */
  updated(changedProperties) {
    if (super.updated) {
      super.updated();
    }

    if ((changedProperties.has('routeData') || changedProperties.has('service')) &&
      this.routeData?.params?.page === 'channel' &&
      this.service?.getChannel) {
      const channelID = this.routeData?.params?.id;
      this.loadChannelData(channelID);
    }
  }

  /**
   * Call to load the channel
   *
   * @param {string} id the channel id
   */
  async loadChannelData(id) {
    this.isError = false;
    this.isLoading = true;
    try {
      this.channel = await this.service.getChannel(id);
      this.teabreaks = await this.service.getBreaks(id);
    } catch (e) {
      this.isError = true;
    }
    this.isLoading = false;
  }
}
window.customElements.define('channel-page', ChannelPage);

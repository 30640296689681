import {LitElement, html} from 'lit';
import router from 'page/page.mjs';
import {sharedStyles} from '../shared-styles';
import '../elements/channel-user';
import '../elements/channel-name';
import '../elements/break-form';
/**
 * `breaks-page`
 *  Allow for the creation of a break
 */
export class BreaksPage extends LitElement {
  /**
   * Defines the elements styles
   *
   * @return {CSSResult} the resulting styles
   */
  static get styles() {
    return sharedStyles;
  }

  /**
   * Defined the elements content
   *
   * @return {TemplateResult} the resulting html template
   */
  render() {
    if (this.isError) {
      return html`<p class="error">Oops there has been an error. <a href="/">Go home</a></p>`;
    }

    if (this.isLoading) {
      return html`<p class="loading">Loading your channel...</p>`;
    }

    const nav = html`
    <nav>
      <a href="/channels">Channels</a>
      /
      <a href="/channels/${this.routeData?.params?.id}"><channel-name .channel=${this.channel}></channel-name></a>
      /
      <a href="/channels/${this.routeData?.params?.id}/breaks">Create Break</a>
      /
    </nav>`;

    if (!this.channel?.users?.length) {
      const empty = html`<p class="empty">There aren't any users in this channel</p>`;
      return [nav, empty];
    }

    const form = html`
      <break-form .break=${this.break}></break-form>
    `;
    return [nav, form];
  }

  /**
   * Defines the elements properties
   *
   * @return {object} the props
   */
  static get properties() {
    return {
      /** The data parsed from the route url */
      routeData: {type: Object},
      /** The service for getting data */
      service: {type: Object},
      /** The channel info */
      channel: {type: Object},
      /** If we're loading the channels */
      isLoading: {type: Boolean},
      /** If there was an error loading channels */
      isError: {type: Boolean},
    };
  }

  /** Initialises values of properties */
  constructor() {
    super();
    this.service = {};
    this.channel = {};
    this.isLoading = true;
    this.isError = false;
  }

  /** Add any event listeners */
  async connectedCallback() {
    if (super.connectedCallback) {
      super.connectedCallback();
    }
    this.addEventListener('break-form-submission', this.handleBreakCreation);
  }

  /** Remove any event listeners */
  disconnectedCallback() {
    if (super.disconnectedCallback) {
      super.disconnectedCallback();
    }
    this.removeEventListener('break-form-submission', this.handleBreakCreation);
  }

  /**
   * Fired whenever any property changes and the template updates
   *
   * @param {PropertyValues} changedProperties map of changed properties
   */
  updated(changedProperties) {
    if (super.updated) {
      super.updated();
    }

    if ((changedProperties.has('routeData') || changedProperties.has('service')) &&
      this.routeData?.params?.page === 'breaks' &&
      this.service?.getChannel) {
      const channelID = this.routeData?.params?.id;
      this.loadChannelData(channelID);
    }
  }

  /**
   * Call to load the channel
   *
   * @param {string} id the channel id
   */
  async loadChannelData(id) {
    this.isError = false;
    this.isLoading = true;
    try {
      this.channel = await this.service.getChannel(id);
    } catch (e) {
      this.isError = true;
    }
    this.isLoading = false;
  }

  /**
   * Handles the creation of the break, from the break-form event
   *
   * @param {CustomEvent} event hold form data and submit event
   */
  async handleBreakCreation(event) {
    try {
      const channelID = this.routeData?.params?.id;
      const response = await this.service.createBreak(channelID, event.detail);
      if (!event.detail.is_auto_recurring) {
        router.show(`/channels/${channelID}/breaks/${response.id}/runs`);
      }
      if (event.detail.is_auto_recurring) {
        router.show(`/channels/${channelID}`);
      }
    } catch (e) {
      this.isError = true;
    }
  }
}
window.customElements.define('breaks-page', BreaksPage);

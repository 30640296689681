/**
 * `service`
 *  Handle the sending of requests, done in this way so all requests
 *  can use the same method and updates only required in one place
 *
 */
export default class Service {
  /**
   * Constructs class
   *
   * @param {Auth} auth the auth service
   * @param {string} apiURI the root uri of api
   */
  constructor(auth, apiURI) {
    this.auth = auth;
    this.root = apiURI;
  }

  /**
   * Creates a headers object including the accepting only json responses
   * Adding any authorization header
   *
   * @return {object} Returns the body
   */
  async getHeaders() {
    const header = new Headers();
    header.set('accept', 'application/json');
    header.set('content-type', 'application/json');
    try {
      const token = await this.auth.getTokenSilently();
      header.set('Authorization', `Bearer ${token}`);
    } catch (e) {
      // squash login error as just don't add the header
    }
    return header;
  }

  /**
   * Used to send the requests off, if 401 unauthorised then redirect to
   * the login page
   *
   * @param {string} url The end point you wish to make the request to
   * @param {string} method The request method, defaults to get e.g. GET, POST
   * @param {object} body Any body you want to send off
   * @return {Promise<object>} Returns a promise based on the request
   */
  async send(url, method, body) {
    const headers = await this.getHeaders();
    const requestOptions = {
      url,
      method,
      mode: 'cors',
      cache: 'default',
      headers,
    };

    if (body) {
      const requestBody = JSON.stringify(body);
      requestOptions.body = requestBody;
    }

    const request = new Request(`${this.root}${requestOptions.url}`, requestOptions);
    try {
      const res = await fetch(request);
      if (res.ok) {
        return res.json();
      } if (res.status === 401) {
        await this.auth.loginWithRedirect({
          redirect_uri: window.location.origin,
        });
        return;
      }
      return Promise.reject(res);
    } catch (e) {
      return Promise.reject(e);
    }
  }

  /**
   * Get channels
   *
   * @return {Promise} the response
   */
  async getChannels() {
    return this.send('/channels');
  }

  /**
   * Get a specific channel
   *
   * @param {string} id the channel id
   * @return {Promise} the response
   */
  async getChannel(id) {
    return this.send(`/channels/${id}`);
  }

  /**
   * Get tea breaks
   *
   * @param {string} id the channel id
   * @return {Promise} the response
   */
  async getBreaks(id) {
    return this.send(`/channels/${id}/breaks`);
  }

  /**
   * Get a specific break
   *
   * @param {string} channelID the channel id
   * @param {string} id the break id
   * @return {Promise} the response
   */
  async getBreak(channelID, id) {
    return this.send(`/channels/${channelID}/breaks/${id}`);
  }

  /**
  * Create a tea break for a given channel
  *
  * @param {string} channelID the channel id
  * @param {any} data hold the data for the break
  * @return {Promise} the response
  */
  async createBreak(channelID, data) {
    return this.send(`/channels/${channelID}/breaks`, 'POST', {
      ...data,
      group_size: Number.parseFloat(data.group_size),
      channel_id: channelID,
    });
  }

  /**
  * Update a tea break
  *
  * @param {string} id the break id
  * @param {string} channelID the channel id
  * @param {any} data hold the data for the break
  * @return {Promise} the response
  */
  async updateBreak(id, channelID, data) {
    return this.send(`/channels/${channelID}/breaks/${id}`, 'PUT', {
      ...data,
      group_size: Number.parseFloat(data.group_size),
      channel_id: channelID,
    });
  }

  /**
   * Create a run instance for a break
   *
   * @param {string} channelID the channel id
   * @param {string} id the break id
   * @param {Array<string>} users the users to include in the run
   * @param {Boolean} isStarted if the run should start immediately
   * @return {Promise} the response
   */
  async createBreakRun(channelID, id, users, isStarted) {
    return this.send(`/channels/${channelID}/breaks/${id}/runs`, 'POST', {
      is_started: isStarted,
      users,
    });
  }

  /**
   * Gets previous run instances for a break
   *
   * @param {string} channelID the channel id
   * @param {string} id the break id
   * @return {Promise} the response
   */
  async getBreakRuns(channelID, id) {
    return this.send(`/channels/${channelID}/breaks/${id}/runs?is_started=true`);
  }

  /**
   * Update a run instance for a break
   *
   * @param {string} channelID the channel id
   * @param {string} breakID the break id
   * @param {string} id the run id
   * @param {Boolean} isStarted if the run should start immediately
   * @return {Promise} the response
   */
  async updateBreakRun(channelID, breakID, id, isStarted) {
    return this.send(`/channels/${channelID}/breaks/${breakID}/runs/${id}`, 'PUT', {
      is_started: isStarted,
    });
  }
}


import {LitElement, html, css} from 'lit';
import {sharedStyles} from '../shared-styles';
/**
 * `channel-user`
 *  Handle displaying a user
 */
export class ChannelUser extends LitElement {
  /**
   * Defines the elements styles
   *
   * @return {CSSResult} the resulting styles
   */
  static get styles() {
    const style = css`
      :host {
        display: flex;
        flex-direction: column;
        width: 100px;
        min-height: 175px;
      }

      img.photo {
        height: 100px;
        width: 100px;
      }

      h3 {
        font-size: 1em;
        margin: 10px 0 0;
      }

      p {
        font-size: 0.75em;
        margin: 5px 0;
      }`;

    return [sharedStyles, style];
  }

  /**
   * Defined the elements content
   *
   * @return {TemplateResult} the resulting html template
   */
  render() {
    return html`
        <img class="photo" src="${this.user.profile_picture}" alt="${this.user.name} profile photo" />
        <h3 class="name">${this.user.name}</h3>
        <p class="status">${this.user.status_text}</p>
    `;
  }

  /**
   * Defines the elements properties
   *
   * @return {object} the props
   */
  static get properties() {
    return {
      /** The user info */
      user: {type: Object},
    };
  }

  /** Initialises values of properties */
  constructor() {
    super();
    this.user = {};
  }
}
window.customElements.define('channel-user', ChannelUser);

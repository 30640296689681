import {LitElement, html} from 'lit';
/**
 * `channel-name`
 *  Handle displaying a user
 */
export class ChannelName extends LitElement {
  /**
   * Render template without shadow DOM.
   * @return {any} the render root
   */
  createRenderRoot() {
    return this;
  }

  /**
   * Defined the elements content
   *
   * @return {TemplateResult} the resulting html template
   */
  render() {
    if (this.channel?.is_private) {
      return html`🔒${this.channel.name}`;
    }
    return html`#${this.channel.name}`;
  }

  /**
   * Defines the elements properties
   *
   * @return {object} the props
   */
  static get properties() {
    return {
      /** The channel info */
      channel: {type: Object},
    };
  }

  /** Initialises values of properties */
  constructor() {
    super();
    this.channel = {};
  }
}
window.customElements.define('channel-name', ChannelName);

/**
 * `forms`
 *  Handle form methods e.g converting it to json
 *
 */
/**
     * Creates a json object including fields in the form
     *
     * @param {Object} form The form to convert to json
     * @return {Object} Returns the body
     */
export const getFormJSON = (form) => {
  const data = new FormData(form);
  return Array.from(data.keys()).reduce((body, key) => {
    // eslint-disable-next-line no-param-reassign
    body[key] = data.get(key);
    return body;
  }, {});
};
